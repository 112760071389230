import React from 'react'
import { createInertiaApp } from '@inertiajs/react'

import _ from 'lodash'
import moment from 'moment/dist/moment'
import 'moment/dist/locale/ru'

import '$lib/vendor/sentry'
import { initServiceWorker } from '$lib/service-worker/helpers'

import '../scss/main.scss'
import { createRoot } from 'react-dom/client'
import { Provider } from 'effector-react/scope'
import { scope } from '$models/scope'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { StoreProvider } from '$lib/page/provider'
import { pageSelfCleans } from '$lib/page/store'

window.moment = moment
window.route = route
window._ = _

moment.locale('ru')

createInertiaApp({
  id: 'app',
  resolve: (name) => {
    const pages = import.meta.glob('./pages/**/*.jsx', { eager: true })
    const page = pages[`./pages/${name}.jsx`]
    const componentName =
      name.indexOf('/') < 0 ? name : _.last(_.split(name, '/'))

    pageSelfCleans.setCurrentRouteHref(window.location.pathname)

    return page[componentName]
  },
  setup({ el, App, props }) {
    if (import.meta.env.VITE_APP_ENV === 'local') {
      console.log(props)
    }

    return createRoot(el).render(
      <StoreProvider>
        <Provider value={scope}>
          <EffectorContextProvider>
            <App {...props} />
          </EffectorContextProvider>
        </Provider>
      </StoreProvider>,
    )
  },
})

if (['dev_ssl', 'production'].includes(import.meta.env.MODE)) {
  initServiceWorker()
}
